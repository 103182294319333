import { useCallback, useEffect, useState } from 'react';

import { IFunFact } from '@/interfaces/pages';

import Fact from './Fact.svg';
import { CloseButton, FactButton, FactCircle, FactWrap, TextClose } from './styles';

interface IFunFactBlock {
    content: IFunFact;
}

const FunFact = ({ content }: IFunFactBlock) => {
    const { heading, factDetail, colour } = content;
    const [showFact, setShowFact] = useState(false);

    const factButtonClickHandler = useCallback(() => {
        document.body.classList.add('no-scroll');
        setShowFact(true);
    }, []);

    const closeButtonClickHandler = useCallback(() => {
        document.body.classList.remove('no-scroll');
        setShowFact(false);
    }, []);

    useEffect(() => {
        const handleCloseKey = (e: KeyboardEvent) => {
            if (e.code === 'Escape' || e.code === 'KeyX') {
                setShowFact(false);
            }
        };

        document.addEventListener('keydown', handleCloseKey);
        return () => document.removeEventListener('keydown', handleCloseKey);
    }, []);

    return (
        <>
            <FactButton onClick={factButtonClickHandler} colour={colour}>
                <span className="desktop">What do our colleagues say?</span>
                <span className="mobile">Click Me</span>
            </FactButton>
            <FactWrap className={showFact ? 'active' : ''}>
                <FactCircle>
                    <Fact width={144} height={123} />
                    <h5>{heading}</h5>
                    <h3>{factDetail}</h3>
                    <TextClose onClick={closeButtonClickHandler}>
                        <p>(x) Close</p>
                    </TextClose>
                    <CloseButton onClick={closeButtonClickHandler} />
                </FactCircle>
            </FactWrap>
        </>
    );
};

export default FunFact;
