/* eslint-disable no-case-declarations */
import dynamic from 'next/dynamic';

const RenderBlock = ({ block }) => {
    const type = block.__type;

    switch (type) {
        case 'HomeHero':
            const HomeHero = dynamic(() => import('@/components/HomeHero'));
            return <HomeHero key={block.id} content={block} />;
        case 'Video':
            const Video = dynamic(() => import('@/components/Video'));
            return <Video key={block.id} content={block} />;
        case 'TwoColumnCTA':
            const TwoColumnCTA = dynamic(() => import('@/components/TwoColumnCTA'));
            return <TwoColumnCTA key={block.id} content={block} />;
        case 'ThreeColumnCTA':
            const ThreeColumnCTA = dynamic(() => import('@/components/ThreeColumnCTA'));
            return <ThreeColumnCTA key={block.id} content={block} />;
        case 'LatestJobs':
            const LatestJobs = dynamic(() => import('@/components/LatestJobs'));
            return <LatestJobs key={block.id} content={block} />;
        case 'TestimonialSlider':
            const TestimonialSlider = dynamic(() => import('@/components/TestimonialSlider'));
            return <TestimonialSlider key={block.id} content={block} />;
        case 'LocationsMarquee':
            const LocationsMarquee = dynamic(() => import('@/components/LocationsMarquee'));
            return <LocationsMarquee key={block.id} content={block} />;
        case 'CurvedHero':
            const CurvedHero = dynamic(() => import('@/components/CurvedHero'));
            return <CurvedHero key={block.id} content={block} />;
        case 'ContentBlock':
            const ContentBlock = dynamic(() => import('@/components/ContentBlock'));
            return <ContentBlock key={block.id} content={block} />;
        case 'JobSearch':
            const JobSearch = dynamic(() => import('@/components/JobSearch'));
            return <JobSearch key={block.id} content={block} />;
        case 'SingleQuote':
            const SingleQuote = dynamic(() => import('@/components/SingleQuote'));
            return <SingleQuote key={block.id} content={block} />;
        case 'ImageTextBlock':
            const ImageText = dynamic(() => import('@/components/ImageText'));
            return <ImageText key={block.id} content={block} />;
        case 'AccordionBlock':
            const AccordionBlock = dynamic(() => import('@/components/AccordionBlock'));
            return <AccordionBlock key={block.id} content={block} />;
        case 'CircularSlider':
            const CircularSlider = dynamic(() => import('@/components/CircularSlider'));
            return <CircularSlider key={block.id} content={block} />;
        case 'SlidingCards':
            const SlidingCards = dynamic(() => import('@/components/SlidingCards'));
            return <SlidingCards key={block.id} content={block} />;
        case 'ValuesSlider':
            const ValuesSlider = dynamic(() => import('@/components/ValuesSlider'));
            return <ValuesSlider key={block.id} content={block} />;
        case 'PhotoMarquee':
            const PhotoMarquee = dynamic(() => import('@/components/PhotoMarquee'));
            return <PhotoMarquee key={block.id} content={block} />;
        case 'StatsSlider':
            const StatsSlider = dynamic(() => import('@/components/StatsSlider'));
            return <StatsSlider key={block.id} content={block} />;
        case 'ApplicationProcess':
            const ApplicationProcess = dynamic(() => import('@/components/ApplicationProcess'));
            return <ApplicationProcess key={block.id} content={block} />;
        default:
            return <></>;
    }
};

export default RenderBlock;
