import { GetStaticPaths, GetStaticProps } from 'next';
import Head from 'next/head';

import Footer from '@/components/Footer';
import FunFact from '@/components/FunFact';
import Header from '@/components/Header';
import { HeaderNavigation, FooterNavigation } from '@/config/Navigation/Navigation';
import { IDepartments, ILatestJob, ILocations } from '@/interfaces/jobs';
import { INavigation } from '@/interfaces/navigation';
import { IPage } from '@/interfaces/pages';
import RenderBlock from '@/utils/RenderBlock';
import { getCachedJobs } from '@/utils/Workable';
import { JobsContext } from 'src/context/jobsContext';

import { Pages } from '../config';

interface PageProps {
    page: IPage;
    navigation: {
        header: Array<INavigation>;
        footer: Array<INavigation>;
    };
    workableData: {
        jobs: ILatestJob[];
        departments: IDepartments;
        locations: ILocations;
    };
}

interface Path {
    params: {
        slug: string[];
    };
}

const Page: React.FC<PageProps> = ({ page, navigation, workableData }) => {
    const { blocks, head } = page;
    const { header, footer } = navigation;

    return (
        <JobsContext.Provider value={workableData}>
            <Head>
                <title>{head.title}</title>
                <meta name="description" content={head.description} key="desc" />
                <meta property="og:title" content={head.title} />
                <meta property="og:description" content={head.description} />
            </Head>
            <Header items={header} />
            {blocks.map((block) => (
                <RenderBlock key={block.id} block={block} />
            ))}
            <Footer items={footer} />
            {page.fact && <FunFact content={page.fact} />}
        </JobsContext.Provider>
    );
};

export const getStaticProps: GetStaticProps = async ({ params }) => {
    let slug = params?.slug ? `${params?.slug[0]}` : 'home';
    if (Array.isArray(params?.slug)) {
        slug = params?.slug.join('/');
    }
    const currentPage = Pages.filter((page) => page.slug === slug)[0];
    const workableData = await getCachedJobs();

    return {
        props: {
            page: currentPage,
            navigation: {
                header: HeaderNavigation,
                footer: FooterNavigation,
            },
            workableData: workableData,
        },
    };
};

export const getStaticPaths: GetStaticPaths = async () => {
    const paths: Path[] = [{ params: { slug: [''] } }];

    Pages.map((page: IPage) => {
        const slugArr = page.slug.split('/');
        if (page.slug) {
            paths.push({ params: { slug: slugArr } });
        }
    });

    return {
        paths,
        fallback: false,
    };
};

export default Page;
